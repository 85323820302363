<template>

  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-light width-available text-center">
        <strong>
          Chopbet {{ $t("deposit") }}
        </strong>
      </div>
    </section>
    <div class="px-2">
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#1" aria-expanded="false" aria-controls="1">
          <strong>{{ $t("howToDeposits") }}</strong>
        </div>
        <div class="promo-content text-light collapse p-2 show" id="1">
          <!--Some html static content-->
          <p>{{ $t("toStartBetting") }}
          </p>
          <p>1. {{ $t("onlineDeposit") }} <br>
            {{ $t("enterAmount") }}.<br>
            {{ $t("clickPay") }}
          </p>
          <p>2. {{ $t("paybillOption") }}<br>
            {{ $t("goToMpesaMenu") }}<br>
            {{ $t("selectPaymentServices") }}<br>
            {{ $t("clickOnPaybill") }}<br>
            {{ $t("enterBusinessNumberAs") }}  <br>
            {{ $t("enterAccountNumberAs") }}<br>
            {{ $t("enterAmountToTransfer") }}<br>
            {{ $t("enterMpesaPinAndConfirm") }}<br>
            {{ $t("receiveSmsConfirmation") }}
          </p>
          <p>
            {{ $t("disclaimer") }}:<br>
            {{ $t("chopbetAccept") }}
          </p>
        </div>
      </section>


    </div>

    <!-- <section class="social-links" style=" ">
       <div class="text-light width-available text-center">
        <strong>
          Frequently Asked Questions
        </strong>
      </div>
    </section> -->
  </div>

</template>

<script>

export default {
  name: 'DepositHowTo',
  components: {
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "deposit-how-to");
    this.reloadProfile();

  }

}
</script>